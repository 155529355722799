import { apiPut } from "../../../api/apiPut";

export const reIndexAllItems = (items) => {
  let parentIndex = -1;
  const newOrderItems = items.map((row, index) => {
    if (row.isSubItem) {
      return { ...row, ln: index + 1, index: parentIndex };
    } else if (row.isSubItem === false) {
      parentIndex++;
      const output = { ...row, ln: index + 1, index: parentIndex };
      return output;
    }
    return row.sort((a, b) => a.ln - b.ln);
  });

  return newOrderItems;
};

/**
 * Function to start the process of update the items.
 * This process will be a recursive process
 */
export const updateItemsProcess = async (itemsToUpdate, subOrderId) => {
  const updatedItem = await updateItem(
    itemsToUpdate[0],
    subOrderId,
    itemsToUpdate,
    itemsToUpdate.length
  );
  return updatedItem;
};

/**
 * This function will be called itemsAmount(amount of items to update) times.
 * When the last item finish to update this function will come back to the updateItemsProcess
 * and updateItemsProcess  will return the final response
 */
const updateItem = async (
  itemToUpdate,
  subOrderId,
  itemsToUpdate,
  itemsAmount
) => {
  const response = await apiPut.lineItem(itemToUpdate, subOrderId);
  if (itemToUpdate.ln + 1 <= itemsAmount) {
    await updateItem(
      itemsToUpdate[itemToUpdate.ln],
      subOrderId,
      itemsToUpdate,
      itemsAmount
    );
  } else {
    return response;
  }
};

/**
 * Set if the subOrder has backorders
 * @param {object} items
 * @returns {boolean}
 */
export const hasBackOrders = (items) => {
  const thereAreBo = items.filter((item) => {
    if (item.bo > 0) {
      return true;
    }
    return false;
  });

  return thereAreBo.length > 0 ? true : false;
};

/**
 * Set if the subOrder has backorders
 * @param {object} dataObject
 * @param {array} dataObject.boData - array of Back Orders status
 * @param {number} dataObject.productCode - code of the product
 * @returns {Array} array of messages about the status of the BO
 */
export const getMessagesOfBackOrdersProcess = ({
  backorderData,
  productCode,
}) => {
  if (productCode) {
    const selectedProduct = backorderData.find(
      (product) => product.product_code === productCode
    );
    if (selectedProduct?.hotsheet_request) {
      if (
        selectedProduct.hotsheet_request?.prod_qty <
        selectedProduct.hotsheet_request?.qty
      ) {
        return [
          {
            id: productCode,
            message: `The product ${productCode} has a Back Order request started.`,
          },
        ];
      }
    }
  } else {
    return backorderData
      .filter((d) => {
        return d.hotsheet_request?.prod_qty < d.hotsheet_request?.qty;
      })
      .map((p) => ({
        id: p.product_code,
        message: `The product ${p.product_code} has a Back Order request started.`,
      }));
  }

  return [];
};

/**
 * Set several data on a item when a user select a product
 * @param {string} product - product id
 * @param {object} discountSchedule - price information
 * @param {array} discountSchedules - array of discount types
 * @param {number} ln - number of the item on the list
 * @param {array} allItems - array of items
 * @returns {object} item with filled data
 */
export const setProductData = ({
  product,
  discountSchedule,
  discountSchedules,
  ln,
  allItems,
  productCode,
}) => {
  const clonedItems = JSON.parse(JSON.stringify(allItems));
  const finalItems = clonedItems.map((item) => {
    if (item.ln === ln) {
      return {
        ...item,
        productCode,
        avail: product.warehouses[0]?.qty_available
          ? product.warehouses[0].qty_available
          : 0,
        description: product.description,
        warehouses: product.warehouses,
        possible_tq: product.protire_wh,
        wh: {
          id: product.warehouses[0].warehouse_id,
          name: product.warehouses[0].warehouse_name,
        },
        weight: 0,
        unitWeight: product.weight,
        bo: 0,
        qrd: 0,
        tq: 0,
        hasPriceOverride: 0,
        item_price: Number(discountSchedule.price),
        ext: 0,
        wsurch: 0,
        d: discountSchedule.discount_schedule,
        s: discountSchedule.price_type,
        availableDiscounts: discountSchedules,
      };
    }
    return item;
  });

  return finalItems;
};

/**
 * Function to calculate the backOrdered of a item
 * @param {int} qrdValue
 * @param {int} availValue
 * @returns {int}
 */
export const calculateBackOrdered = ({ qrdValue, availValue }) => {
  let backOrdered = qrdValue;
  if (qrdValue > availValue && availValue >= 0) {
    backOrdered = qrdValue - availValue;
  } else if (qrdValue <= availValue) {
    backOrdered = 0;
  }
  return backOrdered;
};
