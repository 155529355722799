export const defaultCredit = {
  credit_memo_num: 0,
  customer: undefined,
  customer_invoice_num: 0,
  date_of_memo: "2000-01-01",
  total_credit: 0.0,
  status: 0,
  hold_status: 0,
  freight_amount: 0.0,
  memo_amount: 0.0,
  total_pieces: 0,
  next_line_num: 1,
  freight_line: {
    name: "???",
    freight_line_num: 129,
  },
  freight_credit: 0.0,
  non_ar_amount: 0.0,
  mounted_sales: 0.0,
  mounted_pieces: 0,
  mounted_cost: 0.0,
  non_ar_cost: 0.0,
  cost_of_goods: 0.0,
  credit_type: 0,
  hold_date: "2000-01-01",
  date_entered: "2000-01-01",
  entered_by: "",
  warehouse: 0,
  tax: 0.0,
  stock_transfer: "N",
  freight_line: 129,
  return_prepaid: "N",
  approved_by: "",
  appraised_by: "",
  handling_charge: 0,
  return_customer_name: "",
  return_street: "",
  return_route: "",
  return_city: "",
  return_state: "",
  return_zip: "",
  incoming_freight: 0.0,
  credit_reason: 0,
  total_weight: 0.0,
  apply_to_po: "",
  creditcard_charge: 0.0,
  ref_invoice: 0,
  surcharge: 0.0,
  direct_ship: "N",
};

export const defaultCreditItem = {
  recnum: 0,
  discount_schedule: "",
  comments: "",
  extended_cost: 0.0,
  qty_shipped: 0,
  percent_allowed: 100,
  credit_amount: 0.0,
  sale_price: 0.0,
  extended_credit: 0.0,
  item_price: 0.0,
  product_code: "",
  credit_memo: 0,
  added_to_stock: "",
  order: 0,
  item_type: 0,
  description: "",
  sequence_num: 0,
  special_price: "",
  manufacturer_defect: "",
  in_house: "",
  serial_num: "",
  problem_num: 8,
  wheel_num: 0,
  unit_weight: 0.0,
  subitems: [],
};
