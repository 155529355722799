import React, { memo, useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { Button, Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FaxEmailForm from "./FaxEmailForm";
import { printOrderConfirmation } from "../../../store/slices/subOrderSlice";
import { useDispatch } from "react-redux";

function FaxEmail() {
  const [openPopup, setOpenPopup] = useState(false);
  const dispatch = useDispatch();

  return (
    <div>
      <div variant="primary me-2" onClick={() => setOpenPopup(true)}>
        <span className="me-2">Send Confirmation</span>
        <AiOutlineMail />
      </div>
      <Modal
        show={openPopup}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => setOpenPopup(false)}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="p-5" closeButton>
          <Modal.Title>
            <Col>
              <h3 className="h5">Email/Fax Order Details</h3>
            </Col>
          </Modal.Title>
          <Col style={{ paddingRight: 10 }}>
            {/* TODO: This needs to be connected to back end */}
            <Button
              className="f-right"
              onClick={() => {
                dispatch(printOrderConfirmation());
              }}
            >
              Print Confirmation
            </Button>
          </Col>
        </Modal.Header>
        <Modal.Body className="p-5">
          <FaxEmailForm closePopup={() => setOpenPopup(false)} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default memo(FaxEmail);
